<script type="text/ecmascript-6">

import DashboardLayout from '@/layouts/Dashboard.vue'
import orderReservationsService from '@/services/orderReservations'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            orderReservationReclaims: [],
        }
    },
    methods: {

        async loadOrderReservationReclaims() {

            let response = await orderReservationsService.listReclaimsWaiting()
            this.orderReservationReclaims = response.data
        },
    },
    created() {

        this.loadOrderReservationReclaims()
    },
    computed: {
        orderPublicId() {

            return this.$route.params.orderId
        },   
        reclaimsCleaned() {

            let reclaims = []

            this.orderReservationReclaims.forEach(reclaim => {

                if (reclaim.orderPublicId == this.orderPublicId) reclaims.push(reclaim)
            })

            return reclaims.sort((a, b) => b.created_at - a.created_at)
        },
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>Order Reservation Reclaims for {{ orderPublicId }}</h2>
        <table class="table table-striped">
            <thead>
                <th></th>
                <th>Order ID</th>
                <th>Created at</th>
                <th>Reason</th>
            </thead>
            <tbody>
                <tr v-for="orderReservationReclaim in reclaimsCleaned" :key="orderReservationReclaim.jobId">
                    <td><router-link :to="{name: 'orderReservationReclaimDetail', params: {'jobId' : orderReservationReclaim.jobId}}"><i class="bi bi-eye"></i></router-link></td>
                    <td>{{orderReservationReclaim.orderPublicId}}</td>
                    <td>{{orderReservationReclaim.created_at * 1000 | date}}</td>
                    <td>{{orderReservationReclaim.reason}}</td>
                </tr>
            </tbody>
        </table>
    </dashboard-layout>
</template>
