<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import orderServices from '../services/order'
import userServices from '../services/user'
import transferServices from '../services/transfer'
import kycServices from '../services/kyc'
import mediaMixin from '@/mixins/media.js'
import couponServices from '@/services/coupon.js'
import StandardModal from '../components/StandardModal.vue'

export default {
    
    components: {DashboardLayout, StandardModal},
    mixins: [mediaMixin],
    data() {

        return {

            order: null,
            userPhoneNumber: null,
            isLoading: false,
            kycId: null,
            couponState: null,
            photosLoading: false,
            resellerPhotos: [],
            resellerPhotosUpdated: [],
            couponStateCoupons: [],
            errorMessage: null
        }
    },
    methods: {
        async getCouponState() {

            let response = await couponServices.getCouponState(this.order.coupon_id)
            this.couponState = response.data
            this.couponStateCoupons = this.couponState.coupons
            console.log(this.couponState);
        }, 
        async loadOrderDetails() {

            let orderId = this.$route.params.orderId
            let response = await orderServices.getOrderDetails(orderId, true)
            this.order = response.data
        },
        async getUserPhoneNumber() {

            let response = await userServices.getUserPhoneNumber(this.order.user.id)
            if (response.data != "") {

                this.userPhoneNumber = response.data
            } else {

                this.userPhoneNumber = "N/A"
            }
        },
        getCommissionRatio(order) {

            return (order.commission_excl_tax_price / order.total_price * 100).toFixed(2)
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        async downloadTransfer() {

            let response = await transferServices.get(this.order.public_id, this.order.reseller_id)
            this.openFile(response.data, 'greendid-transfer-' + this.order.public_id + '.pdf')
        },
        async createOrGetEmptyKyc() {

            this.isLoading = true
            try {
                let response = await kycServices.createOrGetEmpty(this.order.user_id)
                this.kycId = response.data.id
                this.$router.push({name: 'kycDetail', params:{kycId: this.kycId}})
            }
            catch (error) {
                
                if (error.response.status === 422) {

                    this.$router.push({name: 'kycDetail', params:{kycId: this.kycId}})
                }
            }
            this.isLoading = false
        },
        async generateDeliveryNote() {

            this.isLoading = true

            try {

                await orderServices.generateDeliveryNote(this.order.public_id, this.order.user_id)
                this.loadOrderDetails()
            }
            catch(error) {

                console.log(error);
            }

            this.isLoading = false
        },
        async downloadMerchantInvoice() {

            let response = await orderServices.getMerchantInvoice(this.order.public_id)
            let extension = this.order.invoice_filename.split('.').pop()
            this.openFile(response.data, 'greendid-merchant-invoice-' + this.order.public_id + '.' + extension)
        },
        async loadPhotos() {

            this.photosLoading = true
            let response = await orderServices.getPhotos(this.order.public_id, this.order.user.id)

            this.resellerPhotos = response.data

            this.resellerPhotos.forEach(element => {
                
                element = "data:image/png;base64," + element.content
                this.resellerPhotosUpdated.push(element)
            });

            this.photosLoading = false
        },
        async validateOrder(accept) {

            await orderServices.validateOrder(this.order.public_id, accept)
            this.$router.go(this.$router.currentRoute)
        },
        async cancelCoupons(couponId) {
            this.isLoading = true
            this.errorMessage = null
            let response = await couponServices.cancelCoupons(couponId)

            if (response.data) {

                location.reload()
            }
            else {

                this.errorMessage = 'Error while canceling coupon'
            }
            this.isLoading = false
        },
    },
    created() {

        this.loadOrderDetails()
    },
    computed: {
        reason() {

            return this.order.status.id === 9 ? this.order.refused_reason : this.order.counter_offer.reason
        },
        latePayout() {

            var payoutDateParts = this.order.payout_date.split("/");
            var payoutDateTimestamp = new Date(payoutDateParts[2], payoutDateParts[1] - 1, payoutDateParts[0]);
            var date = new Date();
            date.setDate(date.getDate() - 1)

            return payoutDateTimestamp.getTime() < date.getTime() ? true : false
        },
        userPrice() {

            if (this.order == undefined) return 0

            if (this.order.coupon_type == 'IMMEDIAT_STORE' && this.order.counter_offer != undefined) {

                return (Number(this.order.counter_offer.to_price) + Number(this.order.contribution_amount != undefined ? this.order.contribution_amount : 0)) * 100
            }

            return (Number(this.order.price) + Number(this.order.contribution_amount != undefined ? this.order.contribution_amount : 0)) * 100
        },

        displayCancelCouponBtn() {

            return this.couponState.status != 'cancelled' && this.couponStateCoupons.length > 0 && this.couponStateCoupons.every(coupon => !coupon.isUsed) ? true : false
        },
    },
}
</script>
<template>
    <dashboard-layout>
        <div v-if="order != undefined">
            <h2>{{order.public_id}}</h2>
            <h2 v-if="order.is_fraud" class="text-danger">** Fraud **</h2>
            <h4 class="mt-5">
                Order
                <button class="btn btn-sm btn-danger ml-4"  v-if="order.status.id === 6" @click="$router.push({name: 'orderPrices'})">Edit prices</button>
            </h4>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>ID</th>
                        <td class="align-middle">{{order.public_id}}</td>
                    </tr>
                    <tr>
                        <th>URL</th>
                        <td class="align-middle">{{order.front_url}}</td>
                    </tr>
                    <tr>
                        <th>Merchant</th>
                        <td class="align-middle"><img width="50px" :src="getMerchantAsset(order.merchant.id, order.merchant.asset_logo_name)" /></td>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <td class="align-middle">{{order.created_at | date}}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td class="align-middle">{{order.status.name}}</td>
                        
                    </tr>
                    <tr v-if="order.status.id === 9">
                        <th>Reason</th>
                        <td class="align-middle">
                            {{ reason}}
                        </td>
                    </tr>
                    <tr v-if="order.status.id === 9">
                        <th>Reseller's photo(s)</th>
                        <td role="button" class="align-middle text-primary" @click="loadPhotos">Show <span v-if="photosLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> </td>
                    </tr>
                    <tr>
                        <th>Transfer Signed</th>
                        <td class="align-middle">{{order.is_transfer_signed == true ? 'Yes' : 'No'}}</td>
                    </tr>
                    <tr v-if="order.is_transfer_signed">
                        <th >Transfer</th>
                        <td class="align-middle">
                            <button @click="downloadTransfer" class="btn btn-primary">Download</button>
                        </td>
                    </tr>
                    <tr>
                        <th>Coupon Type</th>
                        <td class="align-middle">{{order.coupon_type}}</td>
                    </tr>
                    <tr>
                        <th>Has coupon</th>
                        <td class="align-middle">{{order.coupon_id ? 'Yes' : 'No'}}</td>
                    </tr>
                    <tr>
                        <th>Payment type</th>
                        <td class="align-middle">{{order.payment_type }}</td>
                    </tr>
                    <tr v-if="order.payment_type === 'cash' && (order.status.id === 5 || order.status.id === 10)">
                        <th>Payout date</th>
                        <td class="align-middle">{{order.payout_date }} <span v-if="!order.has_payout && latePayout" class="text-danger">(en retard)</span></td>
                    </tr>
                    <tr v-if="[6211121839, 3919794380].includes(order.merchant.id) && order.coupon_id != null">
                        <th>Coupon state</th>
                        <td class="align-middle">
                            <button v-if="couponState == null" class="btn btn-primary" @click="getCouponState">View</button>
                            <div v-else>
                                {{ couponState.public_id }} / {{ couponState.status }}
                                <ul>
                                    <li v-for="(coupon, index) in couponState.coupons" :key="index">{{ coupon.amount | moneyInteger }} => {{ coupon.isUsed  ? 'used' : 'not used' }}</li>
                                </ul>
                                <button class="btn btn-danger" v-if="displayCancelCouponBtn" @click="cancelCoupons(order.coupon_id)">Cancel coupons<span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></button>
                                <p v-if="errorMessage">{{ errorMessage }}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Reseller</th>
                        <td class="align-middle">{{order.reseller.name}}</td>
                    </tr>
                    <tr>
                        <th>User Price</th>
                        <td class="align-middle text-right">{{userPrice | moneyInteger}}</td>
                    </tr>
                    <tr v-if="order.contribution_amount > 0">
                        <th>Greendid Price</th>
                        <td class="align-middle text-right">{{order.price * 100 | moneyInteger}}</td>
                    </tr>
                    <tr v-if="order.contribution_amount > 0">
                        <th>Contribution amount</th>
                        <td class="align-middle">{{order.contribution_amount * 100 | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>Logistic</th>
                        <td class="align-middle text-right">{{order.logistic_excl_tax_price * 100 | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>Commission</th>
                        <td class="align-middle text-right">{{order.commission_excl_tax_price * 100 | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>Total Price</th>
                        <td class="align-middle text-right">{{order.total_price * 100 | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>%Commission</th>
                        <td class="align-middle text-right">{{getCommissionRatio(order)}} %</td>
                    </tr>
                    <tr>
                        <th>Brand campaign</th>
                        <td v-if="order.brand_campaign" class="align-middle">
                            {{order.brand_campaign.name}} ({{ order.bonus_amount }} €) - 
                            <router-link v-if="order.has_brand_campaign_code" :to="{name: 'orderBrandCampaignCodes', params: {'orderId': order.public_id}}">Has code</router-link>
                            <span v-else>No code</span>
                        </td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                </tbody>
            </table>
            <h4 v-if="order.contract" class="mt-5">Contract</h4>
            <table v-if="order.contract" class="table table-bordered col-md-6">
                <tr>
                    <th>Public ID</th>
                    <td class="align-middle">{{ order.contract.public_id }}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td class="align-middle">{{ order.contract.type }}</td>
                </tr>
                <tr>
                    <th>Start date</th>
                    <td class="align-middle">{{ order.contract.start_at | date }}</td>
                </tr>
                <tr>
                    <th>Order date</th>
                    <td class="align-middle">{{ order.merchant_order_date | date }}</td>
                </tr>
                <tr>
                    <th>Buy price</th>
                    <td class="align-middle text-right">{{ order.merchant_product_price }} €</td>
                </tr>
                <tr>
                    <th>Invoice</th>
                    <td>
                        <span v-if="order.invoice_filename == '' || order.invoice_filename == undefined" class="text-danger">No</span>
                        <button v-else class="btn btn-primary" @click="downloadMerchantInvoice">Download</button>
                    </td>
                </tr>
                <tr>
                    <th>Order Greendid price</th>
                    <td class="align-middle text-right">{{order.price}} €</td>
                </tr>
                <tr>
                    <th>Order Greendid price %</th>
                    <td class="align-middle text-right">{{Math.round(order.price / order.merchant_product_price * 100)}}%</td>
                </tr>
                <tr>
                    <th>Order contribution amount</th>
                    <td class="align-middle text-right">{{order.contribution_amount}} €</td>
                </tr>
                <tr>
                    <th>Order contribution amount %</th>
                    <td class="align-middle text-right">{{Math.round(order.contribution_amount / order.merchant_product_price * 100)}}%</td>
                </tr>
                <tr>
                    <th>Order user total price</th>
                    <td class="align-middle text-right">{{Math.round(Number(order.price) + Number(order.contribution_amount))}} €</td>
                </tr>
                <tr v-if="order.status.id == 11">
                    <th>Validate</th>
                    <td>
                        <button @click="validateOrder(true)" class="btn btn-success">Accept</button>
                        <button @click="validateOrder(false)" class="btn btn-danger ml-2">Refuse</button>
                    </td>
                </tr>
            </table>
            <h4 v-if="order.counter_offer" class="mt-5">Counter offers</h4>
            <table v-if="order.counter_offer" class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>Initial Price</th>
                        <td v-if="order.counter_offer.from_price" class="align-middle">
                            {{ order.counter_offer.from_price * 100 | moneyInteger}}
                        </td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Initial Total Price</th>
                        <td v-if="order.counter_offer.from_total_price" class="align-middle">
                            {{ order.counter_offer.from_total_price * 100 | moneyInteger}}
                        </td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Initial Logistic</th>
                        <td v-if="order.counter_offer.from_logistic_excl_tax_price" class="align-middle">
                            {{ order.counter_offer.from_logistic_excl_tax_price * 100 | moneyInteger}}
                        </td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Initial Commission</th>
                        <td v-if="order.counter_offer.from_commission_excl_tax_price" class="align-middle">
                            {{ order.counter_offer.from_commission_excl_tax_price * 100 | moneyInteger}}
                        </td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td v-if="order.counter_offer.status" class="align-middle">
                            {{ order.counter_offer.status}}
                        </td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Reason</th>
                        <td v-if="order.counter_offer.reason" class="align-middle">
                            {{ reason}}
                        </td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Reseller's photos</th>
                        <td v-if="order.counter_offer" role="button" class="align-middle text-primary" @click="loadPhotos">Show <span v-if="photosLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> </td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                </tbody>
            </table>
            <h4 class="mt-5">Merchant</h4>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td class="align-middle">{{order.merchant.name}}</td>
                    </tr>
                    <tr>
                        <th>Order Date</th>
                        <td v-if="order.merchant.merchant_order_date" class="align-middle">{{order.merchant.merchant_order_date}}</td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Initial Price</th>
                        <td v-if="order.merchant.merchant_product_price" class="align-middle text-right">{{order.merchant_product_price}}</td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Invoice</th>
                        <td class="align-middle">
                            <span v-if="order.invoice_filename == '' || order.invoice_filename == undefined">No</span>
                            <button v-else class="btn btn-primary" @click="downloadMerchantInvoice">Download</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4 class="mt-5">Delivery</h4>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>ID</th>
                        <td v-if="order.latest_delivery_note" class="align-middle">{{order.latest_delivery_note.public_id}}</td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Type</th>
                        <td class="align-middle">{{order.reseller.delivery_type}}</td>
                    </tr>
                    <tr>
                        <th>Tracking</th>
                        <td v-if="order.latest_delivery_note" class="align-middle">{{order.latest_delivery_note.tracking_number}}</td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Carrier</th>
                        <td v-if="order.latest_delivery_note" class="align-middle">{{order.latest_delivery_note.carrier}}</td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td v-if="order.latest_delivery_note" class="align-middle">{{order.latest_delivery_note.status}}</td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Delivery note</th>
                        <td v-if="order.latest_delivery_note" class="align-middle"><a target="_blank" :href="order.latest_delivery_note.url">click</a></td>
                        <td v-else-if="order.latest_delivery_note == undefined && order.status.id === 2" @click="generateDeliveryNote"  class="align-middle"><button :disabled="isLoading" class="btn btn-primary">Generate <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></button></td>
                        <td v-else class="align-middle">N/A</td>
                    </tr>
                    <tr>
                        <th>Ad Valorem insurance</th>
                        <td v-if="order.latest_delivery_note" class="align-middle text-right">{{order.latest_delivery_note.ad_valorem_insurance_amount | moneyInteger}}</td>
                        <td v-else class="align-middle">0</td>
                    </tr>
                </tbody>
            </table>
            <h4 class="mt-5">User</h4>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td class="align-middle">{{order.user.first_name}} {{order.user.last_name}}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td class="align-middle">{{order.user.email}}</td>
                    </tr>
                    <tr>
                        <th>Phone</th>
                        <td v-if="!userPhoneNumber" role="button" class="align-middle text-primary" @click="getUserPhoneNumber">Show</td>
                        <td v-else class="align-middle">{{userPhoneNumber}}</td>
                    </tr>
                    <tr>
                        <th>Identity Proof Validated</th>
                        <td class="align-middle">
                            <p v-if="order.user.validated_identity_proofs_count > 0">
                                <span :key="validatedIdentityProof" v-for="validatedIdentityProof in order.user.validated_identity_proofs"><router-link :to="{name: 'kycDetail', params: {kycId: validatedIdentityProof}}">{{ validatedIdentityProof }}</router-link>&nbsp;</span>
                            </p>
                            <button :disabled="isLoading" @click="createOrGetEmptyKyc" v-if="order.user.validated_identity_proofs_count == 0" class="btn btn-primary">Create or Modify Empty KYC <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></button>
                        </td>
                    </tr>
                   <tr>
                        <th>All orders</th>
                        <td class="align-middle">
                            <router-link @click.native="scrollToTop" :to="{name: 'orders', params: {userOrder: order, userId: order.user_id}}">
                                Show
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4 class="mt-5">Product</h4>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td class="align-middle">{{order.product.name}}</td>
                    </tr>
                    <tr>
                        <th>Category</th>
                        <td class="align-middle">{{order.product.category.name}}</td>
                    </tr>
                    <tr>
                        <th>Brand</th>
                        <td class="align-middle">{{order.product.brand.name}}</td>
                    </tr>
                    <tr>
                        <th>Conditions</th>
                        <td class="align-middle">{{order.conditions}}</td>
                    </tr>
                </tbody>
            </table>
            <standard-modal v-if="resellerPhotosUpdated.length > 0" :closeLabel="'Close'" :title="order.reseller.name + '\'s photos'" @close="resellerPhotosUpdated = []">
                <template v-slot:body>
                    <div class="modal-body">
                        <div class="row p-2 d-flex" :key="index" v-for="item, index in resellerPhotosUpdated">
                            <img class="mb-2 border img-fluid" :src="item">
                        </div>
                    </div>
                </template>
            </standard-modal>
        </div>
    </dashboard-layout>
</template>
